import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Metadata from '~/components/Metadata'
import Masthead from '~/components/Masthead'
import Block from '~/components/Block'
import LinksBlock from '~/components/LinksBlock'
import WhatsHealthyBlock from '~/components/WhatsHealthyBlock'

import { mapCraft } from './mappings'

import { Spacer, DripPattern } from '~/styles/components'

const BigMomentsTemplate = ({ data }) => {
  const craft = mapCraft(data.craft)
  const {
    title,
    metadata,
    masthead,
    steps,
    links
  } = craft.entry
  
  return (
    <div>
      <Metadata
        title={title}
        {...metadata}
      />
      <DripPattern
        color="cream"
        top="300px"
      />
      <Masthead
        sectionHeading="Big moments"
        {...masthead}
      />
      {steps.map((step, i) =>
        <Block
          key={i}
          {...step}
          number={i + 1}
        />
      )}
      <Spacer size="sm" />
      <LinksBlock
        links={links}
      />
      <Spacer size="sm" />
      <WhatsHealthyBlock />
    </div>
  )
}

BigMomentsTemplate.propTypes = {
  data: PropTypes.object
}

export default BigMomentsTemplate

export const query = graphql`
query BigMomentsTemplateQuery($id: Craft_QueryArgument) {
  craft {
    entry(id: [$id]) {
      ... on Craft_bigMoments_bigMoments_Entry {
        title
        metadata {
          ... on Craft_metadata_BlockType {
            title: metadataTitle
            description: metadataDescription
            keywords: metadataKeywords
            noFollow: metadataNoFollow
            noIndex: metadataNoIndex
          }
        }
        masthead {
          ... on Craft_masthead_BlockType {
            heading
            subheading
            body
          }
        }
        steps: bigMomentSteps {
          __typename
          ... on Craft_bigMomentSteps_textBlock_BlockType {
            heading
            subheading
            body
          }
          ... on Craft_bigMomentSteps_checklistBlock_BlockType {
            heading
            subheading
            checklistHeading
            checklistSubheading
            checklistItems {
              ... on Craft_checklistItems_BlockType {
                title: itemTitle
                content: itemContent
              }
            }
          }
        }
        links {
          ... on Craft_links_BlockType {
            text: linkText
            url: linkUrl
            description: linkDescription
          }
        }
      }
    }
  }
}
`